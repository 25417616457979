/*************************** Theme Colours****************************/
// Define theme colour scheme
$theme-color-primary: #47b0df;
$theme-color-secondary: #71c853;

$theme-text-color-primary: #212B35;
$theme-text-color-secondary: lighten($theme-text-color-primary, 25%);
$theme-text-color-light: lighten($theme-text-color-primary, 40%);

$theme-bg-light: #EFF2F5;
$theme-bg-dark: $theme-color-primary;

$theme-border-color:  #efefef;
$theme-divider-color: #efefef;

$theme-divider: lighten($theme-text-color-primary, 75%);

$theme-color-new: #5ab66e;
$theme-error: #EC575B;

$smoky-white: #f5f5f5;
$google-color: #d73d32;
$facebook-color: #3b5998;
$twitter-color: #1DA1F2;

$border-color: $theme-border-color;
/*************************** Variables Section. *****************************/
// Create variables to override the default value for variables used in the Bootstrap SCSS files.

$gray-100: lighten($theme-text-color-primary, 65%);
$gray-200: lighten($theme-text-color-primary, 55%);
$gray-300: lighten($theme-text-color-primary, 50%);
$gray-400: lighten($theme-text-color-primary, 45%);
$gray-500: lighten($theme-text-color-primary, 40%);
$gray-600: lighten($theme-text-color-primary, 30%);
$gray-700: lighten($theme-text-color-primary, 20%);
$gray-800: lighten($theme-text-color-primary, 10%);
$gray-900: $theme-text-color-primary;

$theme-colors: (
  "primary":  $theme-color-primary, 
  "secondary":  $theme-text-color-secondary, 
);

/*************************** Import Bootstrap  *****************************/
@import "bootstrap/scss/bootstrap.scss";


/*************************** Theme Styles Section ****************************/
@import "theme/styles.scss"

