/*!  
 * 
*/
@import "mixins";
@import "base";
@import "landing";
@import "features";
@import "pricing";
@import "about";
@import "contact";
@import "auth";

.srv-validation-message {
    color: $theme-error;
}

#mainNav > li:last-child {
    margin-right: 0 !important;
}

.dropdown-toggle::after {
    display: none;
}

p:empty:before {
    content: " ";
    white-space: pre;
}

.cursor-pointer {
    cursor: pointer;
}

.my-editor > p {
    margin-bottom: 0rem;
}

.note-editor {
    background: #fafafa;
    line-height: 0.8em;
}

.note-editor li {
    padding-left: 0.2em;
    padding-bottom: 0.3em;
}

.react-confirm-alert-overlay {
    z-index: 1099 !important;
}

/* Printing Presciption */
@media print { 

    body * {
        visibility: hidden;
    }

    body .card {
        position: unset;
    }

    body .modal-dialog {
        margin: 0;
    }

    body .print-me, body .print-me * {
        visibility: visible;
    }
    
    body .print-me {
        position: absolute;
        width: 870px;
        padding: 0;
        margin: 0;
        top: 0;
        left: 0;
    }

}