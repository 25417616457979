.form-control {
	padding-top: 0.875rem;
	padding-bottom: 0.875rem;
	height: auto;
}

.form-group {
	.error {
		padding-top: 0.875rem;
	}
}