.member-profile {
	top: -50px;
	left: 0;
	
	img {
		width: 100px;
		height: 100px;
	}
}

.team-section {
	.social-list {
		a {
			&:hover {
				color: $theme-color-primary !important;
			}
		}
	}
}


.member-name {
	font-size: 1.25rem;
}

.press-name {
	font-size: 1.25rem;
}

.press-section {
	.card {
		
	}
}

.link-mask {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: block;
	text-align: center;
	.btn {
		display: none;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -71.5px;
		margin-top: -19px;
	}
	&:hover {
		background: rgba(256, 256, 256, 0.9);
		.btn {
			display: inline-block;
		}
		
	}

	
}