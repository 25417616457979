.pricing-plan-name {
	font-size: 1.5rem;
}

.pricing-highlight {
	background: $theme-color-primary;
	color: #fff;
	top: -40px;
	left: 0;
	width: 100%;
	font-weight: bold;
	font-size: 1rem;
}

.price-currency {
	font-family: "Helvetica Neue",Arial,sans-serif;
	font-size: 1rem;
	font-weight:normal;
	position: relative;
	position: relative;
	top: -1.5rem;
	margin-right: 0.125rem;
}

.pricing-item {
	.fa-check-circle {
		color: $theme-color-secondary;
	}
}

.quote-icon-holder {
	background: lighten($theme-color-primary, 35%);
	color: $theme-color-primary;
	width: 60px;
	height: 60px;
	font-size: 1.5rem;
	padding-top: 12px;
}

.doc-figure-holder {
	background: url("/assets/img/doc-figure.png") no-repeat center top;
	background-size: 400px auto;
	min-height: 460px;
}

.map-holder {
	background: url("/assets/images/world-map-with-lines.svg") repeat center center;
	background-size: cover;	
}

.map-item {
	width: 100%;
	
}

.map-item-inner {
	&:after {
		content: "";
		display: block;
		width: 0;
		height: 0;
		position: absolute;
		left: 50%;
		bottom: -16px;
		margin-left: -16px;
		border-left: 16px solid transparent;
		border-right: 16px solid transparent;
		border-top: 16px solid lighten($theme-color-primary, 35%);
	}
}

.map-item-profile {
	width: 40px;
	height: 40px;
}

.map-item-body {
	background: #fff;
}

.map-item-footer {
	background: lighten($theme-color-primary, 35%);
	color: $theme-color-primary;
	font-size: 0.875rem;
	
}

.map-item-point {
	display: inline-block;
	width: 22px;
    height: 22px;
    margin: 2rem auto;
    background-color: $theme-color-primary;
    border: 4px solid #fff;
    animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(71, 176, 223, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(71, 176, 223, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(71, 176, 223, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(71, 176, 223, 0.4);
    box-shadow: 0 0 0 0 rgba(71, 176, 223, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(71, 176, 223, 0);
      box-shadow: 0 0 0 10px rgba(71, 176, 223, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(71, 176, 223, 0);
      box-shadow: 0 0 0 0 rgba(71, 176, 223, 0);
  }
}

.faq-accordion {
	.card-title {
		font-size: 1.25rem;
	}
	a.card-toggle{
		color: $theme-text-color-primary;
		font-weight: normal;
		&:hover {
			text-decoration: none;
			.svg-inline--fa {
				color: $theme-color-primary;
			}
		}
		.svg-inline--fa {
			color: lighten($theme-color-primary, 10%);
		}
	}
	
}


// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
	
	.pricing-item-highlighted {
		margin-top: 40px;
	}
	
}


// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
	
	.map-holder {
		background: url("/assets/images/world-map-with-lines.svg") no-repeat center center;
		background-size: 1200px auto;
		min-height: 700px;
	}
    .map-item {
		width: 300px;
		float: none;
	}
	.map-item-pos1 {
		position: absolute;
		left: 0px;
		top: 0px;
	}
	.map-item-pos2 {
		position: absolute;
		left: 400px;
		top: 110px;
	}
	
	.map-item-pos3 {
		position: absolute;
		right: 80px;
		top: -40px;
	}
	
	.map-item-pos4 {
		position: absolute;
		left: 75px;
		top: 250px;
	}
	
	.map-item-pos5 {
		position: absolute;
		right: 0px;
		top: 240px;
	}

	
}


